import React from "react";
import { Row, Col, Container } from "react-grid-system";
//import CountUp, { useCountUp } from "react-countup";

const GreaterDistance = (props) => {
  const Data = props.data;
  return (
    <Container className="miniStat">
      <Row>
        <Col sm={4} className="miniStatLeft"></Col>
        <Col sm={8} className="miniStatRight">
          <p className="miniBlockSubheader">Century</p>
          <p className="blockData">
          {Data().toLocaleString("en-US")}
          </p>
        </Col>
      </Row>
      <div className="thinLine"></div>
      <div className="detailText">Riding 100+ Miles</div>
    </Container>
  );
};

const DistancePledged = (props) => {
  const Data = props.data;
  //const num = Data()
  // const { countUp, start, update } = useCountUp({
  //   start: 0,
  //   end: Data(),
  //   delay: 10,
  //   duration: 1,
  //   onUpdate: () => console.log("Updated!"),
  // });
  // console.log(Data())

  // useEffect(() => {
  //   update(Data());
  // }, [Data, update]);
  
  return (
    <Container className="miniStat">
      <Row className="statRow">
        <Col sm={4} className="miniStatLeft"></Col>
        <Col sm={8} className="miniStatRight">
          <p className="miniBlockSubheader">Distance</p>
          <p className="blockData"><Data /></p>
        </Col>
      </Row>
      <div className="thinLine"></div>
      <div className="detailText">Total Miles Pledged</div>
    </Container>
  );
};

const RegisteredRiders = (props) => {
  const Data = props.data;
  return (
    <Container className="miniStat">
      <Row>
        <Col sm={4} className="miniStatLeft"></Col>
        <Col sm={8} className="miniStatRight">
          <p className="miniBlockSubheader">Riders</p>
          <p className="blockData">
            {Data().toLocaleString("en-US")}
          </p>
        </Col>
      </Row>
      <div className="thinLine"></div>
      <div className="detailText">Registered For Event</div>
    </Container>
  );
};

const TeamsRegistered = (props) => {
  const Data = props.data;
  return (
    <Container className="miniStat">
      <Row>
        <Col sm={4} className="miniStatLeft"></Col>
        <Col sm={8} className="miniStatRight">
          <p className="miniBlockSubheader">Community</p>
          <p className="blockData">
            <Data />
          </p>
        </Col>
      </Row>
      <div className="thinLine"></div>
      <div className="detailText">Riding On Teams</div>
    </Container>
  );
};

const MiniStat = (props) => {
  const century = props.centuryData;
  const riders = props.riderData;
  const teams = props.teamData;
  const distance = props.distanceData;
  return (
    <Row className="analyticsRow">
      <Col sm={3} className="miniStatContainer">
        <div className="mini1">
          <RegisteredRiders data={riders} />
        </div>
      </Col>
      <Col ssm={3} className="miniStatContainer">
        <div className="mini2">
          <TeamsRegistered data={teams} />
        </div>
      </Col>
      <Col sm={3} className="miniStatContainer">
        <div className="mini3">
          <GreaterDistance data={century} />
        </div>
      </Col>
      <Col sm={3} className="miniStatContainer">
        <div className="mini4">
          <DistancePledged data={distance} />
        </div>
      </Col>
    </Row>
  );
};

export default MiniStat;
