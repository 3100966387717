import React from "react";
import { Container, Row, Col } from "react-grid-system";
import Logo from "../Site/assets/images/summiitwhite.png"

const HeaderBar = () => (
  <Container>
    <Row>
      <Col sm={4}>
      </Col>
      <Col sm={4}>
      <img src={Logo} alt="main" className="logo"></img>
      </Col>
      <Col sm={4}>
        <div className="blockSubheader"></div>
      </Col>
    </Row>
  </Container>
);

export default HeaderBar;
