import React, { useState } from "react";
//import StaticData from "../data/StaticData";
import { Col, Row, Container } from "react-grid-system";
import {useParams} from "react-router-dom"


const RiderList = props => {
  const teamCode = localStorage.teamName
  const data = props.pushData



  const TeamFilter = data.filter(function (data) {
    //console.log(teamSelection())
    return data.regData.onTeam === teamCode;
  });

  const renderTableData = () => {
    return TeamFilter.map((rider, index) => {
      const { lbname, regData, rideData } = rider;
      const percent = (rideData.currentMiles / regData.distancePlanned)*100
      return (
        <tr key={lbname}>
          <td className="riderName">{lbname}</td>
          <td>{rideData.currentMiles} Miles</td>
          <td>{percent.toFixed(2)}%</td>
        </tr>
      );
    });
  };

  const renderTableHeader = () => {
    const header = ["Leaderboard Name", "Current Miles", "% To Goal"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  return (
    <Container className="col8Live">
      <Row className="analyticsRow">
        <Col sm={12}>
          <div className="scroll">
            <table id="riders">
              <tbody>
                <tr>{renderTableHeader()}</tr>
                {renderTableData()}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RiderList;
