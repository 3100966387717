import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-grid-system";
import {useParams} from "react-router-dom"

const Time = () => {
const [currentTime, setTime] = useState("")

  var date = new Date();
  var h = date.getHours() + 1;
  var m = date.getMinutes();
  var s = date.getSeconds(); 
  var session = "AM";

  if (h === 12){
    session = "PM"
  }

  if (h > 12) {
   h = h - 12 
   session = "PM"
  }

  //h = (h < 10) ? "0" + h : h;
  m = (m < 10) ? "0" + m : m;
  s = (s < 10) ? "0" + s : s;


  var showTime = h + ":" + m + ":" + s + " " + session + " EST";

  useEffect(() => {
    setTimeout(()=> {
      setTime(showTime)
    }, 1000)
  }, [showTime])
 

  return (
    <div className="clock">{currentTime} <span className="liveIndicator">(LIVE)</span></div>
  )
}

const HeaderBar = () => {
  const {teamCode} = useParams()
  return(
  <Container>
    <Row>
      <Col sm={4}>
      </Col>
      <Col sm={4} >
      <div className="teamHeaderLive"><p className="blockHeaderLive">{teamCode}</p></div>
      </Col>
      <Col sm={4}>
      <div className="dataAlert">{Time()}</div>
      </Col>
    </Row>
  </Container>
);
  }
export default HeaderBar;
