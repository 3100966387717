import Axios from "axios";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";


const ClassDetails = (props) => {
  const classes = props.data

  const isActive = classes.filter((x) => x.active === true);
  const current = isActive[0]
  const next = isActive[1]

  console.log(current.time, Date.now() / 1000)
  const classID = current.id

  const durationActive = current.duration;
  const instructorActive = current.instructor;
  const nameActive = current.name;
  const typeActive = current.type;
  const timeActive = current.time;
 

  const durationNext = next.duration;
  const instructorNext = next.instructor;
  const nameNext = next.name;
  const typeNext = next.type;
  const timeNext = next.time;


  //const epochNanoseconds = (durationActive * 60) * 1000
  const epochSeconds = durationActive * 60
  const checkTime = timeActive + epochSeconds
  //const currentTime = Date.now()/1000


 useEffect(() => {


  const checkStatus = setInterval(checkClassStatus, 30000)

  function checkClassStatus() {
    if(checkTime <= Date.now()/1000 | timeNext <= Date.now()/1000) {
        console.log(`${classID} is expired`)
        console.log(checkTime, Date.now()/1000)
        clearInterval(checkStatus)

      const update = {"class": classID, "active": false}
       Axios.post("https://us-central1-pelofondo-analytics-dev.cloudfunctions.net/api/setclass", update)
       .then(res => console.log("Next Class Set"))

    } else {
        //console.log(`${classID} is Active`)
        // console.log(checkTime, Date.now()/1000)
    }
  }

 }, [checkTime, classID, timeNext]);
  

   
    

  


  return (
    <Container className="classContainer">
      <Row className="classRow">
        <Col sm={6} className="classStat">
          <div className="bigStatNumTeam" id="currentClass">
          <div className="classHeading">CURRENT CLASS:</div>
          <p className="classTitles">Class Name: <span className="classData">{nameActive}</span></p>
            <p className="classTitles">Instructor: <span className="classData">{instructorActive}</span></p>
            <p className="classTitles">Duration: <span className="classData">{durationActive} MIN</span></p>
            <p className="classTitles">Air Date: <span className="classData">{typeActive}</span></p>
          </div>
        </Col>
        <div className="vlLarge"></div>
        <Col sm={6} className="classStat">
        <div className="bigStatNumTeam" id="currentClass">
        <div className="classHeading">UP NEXT:</div>
        <p className="classTitles">Class Name: <span className="classData">{nameNext}</span></p>
          <p className="classTitles">Instructor: <span className="classData">{instructorNext}</span></p>
          <p className="classTitles">Duration: <span className="classData">{durationNext} MIN</span></p>
          <p className="classTitles">Air Date: <span className="classData">{typeNext}</span></p>
        </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ClassDetails;
