import React from "react";
import { Container, Row, Col } from "react-grid-system";


const FooterBar = () => (
  <Container>
    <Row>
      <Col sm={4}>
      <div className="cred">Made with <span className="miniIcon" role="img" aria-label="heart">❤️</span> and <span className="miniIcon" role="img" aria-label="coffee">☕️</span></div>
      </Col>
      <Col sm={4}>
      
      </Col>
      <Col sm={4}>
      </Col>
    </Row>
  </Container>
);

export default FooterBar;
