import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom"
import { Container, Row, Col } from "react-grid-system";
import GroupStats from "../../liveRideDashboard/GroupStats";
import {db} from "../../fire";
import AllRiderDetails from "../../liveRideDashboard/AllRiderDetails";
import TeamDetails from "../../liveRideDashboard/TeamDetails"
import TeamList from "./TeamListFiltered"
import ClassDetails from "../../liveRideDashboard/ClassDetails";



const MainView = () => {
  const [data, setData] = useState(null)
  const [classes, setClasses] = useState(null)
  const teamName = localStorage.teamName
  const localData = sessionStorage.data
  const eventDate = sessionStorage.event


// if(!localData){
  function call() {
    db.collection("eventRegistrations")
        .where("regData.onTeam", "==", teamName)
        .where("rideData.currentMiles", ">", 0)
        .where("event", "==", eventDate)
        .onSnapshot(function (querySnapshot) {
        var records = [];
        querySnapshot.forEach(function (doc) {
          records.push(doc.data());
        });
        console.log(records)
        setData(records);
      });
        db.collection("schedule")
        .onSnapshot(function (querySnapshot) {
          var records = [];
          querySnapshot.forEach(function (doc) {
            records.push(doc.data());
          });
          setClasses(records);
          // sessionStorage.setItem("data", records.toString())
      });
  }

  useEffect(() => {
    const fetchData = () => setTimeout(() => setData(call), 1000)
    fetchData()
  }, []) 
// }

  
//   if(localData){
//     return <p className="refreshError">Error: Maxiumum Connections Exceeded <br /> This Dashboard updates automatically and cannot be refreshed manually.<br />Please log back in to try again.</p> 
//   }

  if (!data | !classes ) {
    return<p className="loading">Loading...</p> 
  } else if(data.length === 0){
    return <div className="noData"><p className="loading">No Data Exists.  Check back after the first ride has been logged.</p></div>
  } else {

  

  return (
    <Container className="mainView">
        <GroupStats pushData={data} />

        <Row className="liveDataRow">
          <Col sm={4}>
            <AllRiderDetails pushData={data} />
          </Col>
          <Col sm={8}>
            <TeamList pushData={data} />
          </Col>
        </Row>
        <ClassDetails data={classes}/>
    </Container>
  );
};
}

export default MainView;
