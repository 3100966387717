import React, { useState } from "react";

const RiderFeed = (props) => {
  const data = props.data;

  return (
    <div>
      <div style={{ fontSize: 24, color: "#dc493a" }}>RIDER FEED: </div>
      <div style={{ fontSize: 18 }}>
        {data.map((item, index) => (
          <div key={index} style={{color: 'white', fontSize: 16}}>• {item}</div>
        ))}
      </div>
    </div>
  );
};

export default RiderFeed;
