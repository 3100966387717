import React, {useState} from "react";


const RiderFeed = (props) => {
    const data = props.data
   

 
  return (
    <div>
        <div style={{fontSize: 24, color: '#dc493a'}}>RIDER FEED: </div>
     <div style={{fontSize: 16, color: '#999'}}>{data.map((item, index) => (
         <div key={index}>• {item}</div>
     ))}</div>
    </div>
  );
};

export default RiderFeed;


