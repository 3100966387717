import React, { useEffect } from "react";
import Chart from "chart.js";

//import {Century, Miles35, Miles50, Miles62, Miles80, Miles99} from "../data/Calculations"
//import { withRouter } from "react-router-dom";
Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif";


const MilesByVolume = props => {
  const chartRef = React.createRef();
  const d35 = props.data35
  const d50 = props.data50
  const d62 = props.data62
  const d80 = props.data80
  const d99 = props.data99
  const century = props.century

  useEffect (() => {
    const myChartRef = chartRef.current.getContext("2d");

    const { height: graphHeight } = myChartRef.canvas;

    let gradientLine = myChartRef.createLinearGradient(0, 200, 0, graphHeight);
    gradientLine.addColorStop(1, "rgba(34,124,242,.4)");
    gradientLine.addColorStop(0.5, "rgba(34,124,242,.2)");
    gradientLine.addColorStop(0, "rgba(0,0,0,0)");
    new Chart(myChartRef, {
      type: "doughnut",
      data: {
        //Bring in data
        labels: [
          "0 - 34 Miles",
          "35 - 49 Miles",
          "50 - 61 Miles",
          "62 - 79 Miles",
          "80 - 99 Miles",
          "100+ Miles",
        ],
        datasets: [
          {
            label: "Riders",
            data: [d35(), d50(), d62(), d80(), d99(), century()],
            fill: true,
            backgroundColor: [
                "#222222",
                "#2793f2",
                "#193B59",
                "#594f19",
                "#3a1959",
                "#59192e",
            ],
            borderColor: "rgba(34,124,242,1)",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 0,
            left: 0,
            right: 20,
            bottom: 30,
          },
        },
        legend: {
            display: true,
            labels: {
                boxWidth: 10,
                strokeStyle: "#222",
            },
            position: "left",           
        },
        scales: {
          xAxes: [
            {
              ticks: { display: false },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: { display: false },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      },
    });
  }, [century, d35, d50, d62, d80, d99, chartRef])
  
    return (
      <div className="contain">
        <p className="blockSubheader">Number of Riders By</p>
        <p className="blockHeader">Mileage</p>
        <canvas id="myChart" ref={chartRef} />
      </div>
    );
  }


export default MilesByVolume