import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-grid-system";
// import Logo from "../Site/assets/images/pfheaderlogo.png"

const Time = () => {
  const [currentTime, setTime] = useState("");
  const [twelveHour, setTwelveHour] = useState();

  const startTime = 1674280800000
  const endTime = 1674453600000

  var date = new Date();
  var h = date.getHours();
  var m = date.getMinutes();
  var s = date.getSeconds();
  let session = []; 

  
  const epoch = Date.now()

  let australia = epoch + (16 * 3600000);
  let germany = epoch + (7 * 3600000);
  let uk = epoch + (6 * 3600000);
  let est = epoch + (1 * 3600000);
  let cst = epoch;
  let mst = epoch - (1 * 3600000);
  let pst = epoch - (2 * 3600000);

  console.log(australia)





  if (h === 12) {
    session = "PM";
  }

  if (h > 12) {
    h = h - 12;
    session = "PM";
  }

  if (h > 24) {
    h = h - 24;
    session = "AM";
  }

  //h = (h < 10) ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;

  function setSession(hours) {
    if (hours === 12) {
      return "PM";
    }
  
    if (hours > 12) {
      return "PM";
    }
  
    if (hours > 24) {
      return "AM";
    }
     
    if (hours < 12){
      return "AM"
    }

  }

  function twelveHourFormat (hours) {

    if(hours > 12) {
      return hours - 12
    } else if(hours === 0) {
      return 12
    } else {
      return hours
    }

  }
  

  var showTime = h + ":" + m + ":" + s + " " + session + " EST";

  const aushr = new Date(australia).getHours()
  const gerhr = new Date(germany).getHours()
  const euhr = new Date(uk).getHours()
  const esthr = new Date(est).getHours()
  const csthr = new Date(cst).getHours()
  const msthr = new Date(mst).getHours()
  const psthr = new Date(pst).getHours()

  var aus = twelveHourFormat(aushr) + ":" + m + " " + setSession(aushr);
  var ger = twelveHourFormat(gerhr) + ":" + m + " " + setSession(gerhr);
  var eu = twelveHourFormat(euhr) + ":" + m + " " + setSession(euhr);
  var eastern = twelveHourFormat(esthr) + ":" + m + " " + setSession(esthr);
  var central = twelveHourFormat(csthr) + ":" + m + " " + setSession(csthr);
  var mountain = twelveHourFormat(msthr) + ":" + m + " " + setSession(msthr);
  var pacific = twelveHourFormat(psthr) + ":" + m + " " + setSession(psthr);

  const arr = {
    aus: aus,
    ger: ger,
    uk: eu,
    est: eastern,
    cst: central,
    mst: mountain,
    pst: pacific,
  };

  useEffect(() => {
    setTimeout(() => {
      setTime(arr);
    }, 1000);
  }, [showTime]);

  function isOpen(locale) {
   if(locale < startTime || locale > endTime) {
     return <span style={{color: "#444"}}>(Closed)</span>
   } else {
     return <span className="liveIndicator">(LIVE)</span>
   }
  }

  
  

  return (
    <Row style={{marginLeft: 15, marginRight: 15,  justifyContent: 'space-between'}}>
      <div style={{alignItems: 'center', paddingTop: 40}}>
        <div style={{fontSize: 16, justifyContent: 'center'}}>🇦🇺 Australia</div>
        <div style={{fontSize: 16, color: "#dc493a"}}>
          {aus} {isOpen(australia)}
        </div>
      </div>
      <div style={{alignItems: 'center', paddingTop: 40}}>
        <div style={{fontSize: 16, justifyContent: 'center'}}>🇩🇪 Germany</div>
        <div style={{fontSize: 16, color: "#dc493a"}}>
        {ger}  {isOpen(germany)}
        </div>
      </div>
      <div style={{alignItems: 'center', paddingTop: 40}}>
        <div style={{fontSize: 16, justifyContent: 'center'}}>🇬🇧 United Kingdom</div>
        <div style={{fontSize: 16, color: "#dc493a"}}>
        {eu} {isOpen(uk)}
        </div>
      </div>
      <div style={{alignItems: 'center', paddingTop: 40}}>
        <div style={{fontSize: 16, justifyContent: 'center'}}>🇺🇸 Eastern</div>
        <div style={{fontSize: 16, color: "#dc493a"}}>
        {eastern} {isOpen(est)}
        </div>
      </div>
      <div style={{alignItems: 'center', paddingTop: 40}}>
        <div style={{fontSize: 16, justifyContent: 'center'}}>🇺🇸 Central</div>
        <div style={{fontSize: 16, color: "#dc493a"}}>
        {central} {isOpen(cst)}
        </div>
      </div>
      <div style={{alignItems: 'center', paddingTop: 40}}>
        <div style={{fontSize: 16, justifyContent: 'center'}}>🇺🇸 Mountain</div>
        <div style={{fontSize: 16, color: "#dc493a"}}>
        {mountain}  {isOpen(mst)}
        </div>
      </div>
      <div style={{alignItems: 'center', paddingTop: 40}}>
        <div style={{fontSize: 16, justifyContent: 'center'}}>🇺🇸 Pacific</div>
        <div style={{fontSize: 16, color: "#dc493a"}}>
        {pacific} {isOpen(pst)}
        </div>
      </div>
    </Row>
  );
};


const HeaderBar = () => (
  <Container>
    
{Time()}
    
  </Container>
);

export default HeaderBar;
