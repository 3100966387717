import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom"
import { Container, Row, Col } from "react-grid-system";
//import RiderStats from "./RiderStats";
import GroupStats from "./GroupStats";
// import { RiderList } from "./TeamList";
import {db} from "../fire";
import AllRiderDetails from "./AllRiderDetails";
import TeamDetails from "./TeamDetails"
import TeamList from "./TeamList"
import ClassDetails from "./ClassDetails";



const MainView = () => {
  const [data, setData] = useState(null)
  const [classes, setClasses] = useState(null)
  const { teamCode } = useParams()
  

  function call() {
    db.collection("eventRegistrations")
        .where("regData.onTeam", "==", teamCode)
        .where("metrics.currentMiles", ">", 0)
        .where("event", "==", "APR2022")
        .onSnapshot(function (querySnapshot) {
        var records = [];
        querySnapshot.forEach(function (doc) {
          records.push(doc.data());
        });
        console.log(records)
        setData(records);
      });
        db.collection("schedule")
        .onSnapshot(function (querySnapshot) {
          var records = [];
          querySnapshot.forEach(function (doc) {
            records.push(doc.data());
          });
          setClasses(records);
      });
  }

  
  useEffect(() => {
    const fetchData = () => setTimeout(() => setData(call), 1000)
    fetchData()
  }, []) 


  if (!data | !classes ) {
    return<p className="loading">Loading...</p> 
  } else if(data.length === 0){
    return <div><p className="loading">No Data Exists.  Check back after the first ride has been logged.</p></div>
  } else {

  

  return (
    <Container className="mainView">
        <GroupStats pushData={data} />

        <Row className="liveDataRow">
          <Col sm={4}>
            <AllRiderDetails pushData={data} />
          </Col>
          <Col sm={8}>
            <TeamList pushData={data} />
          </Col>
        </Row>
        <ClassDetails data={classes}/>
    </Container>
  );
};
}

export default MainView;
