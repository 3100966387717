import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/Menu";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PeloFondoDashboard from "../liveRideDashboard/AdminDashboard";
import StrideDashboard from '../strideLiveDashboard/AdminDashboard'
import RiderDashboard from "../liveRideDashboard/RiderDashboard"
import LookupDashboard from "../registrationLookup/RegistrationLookupDashboard"
import RegistrationDashboard from "../registrationDashboard/Dashboard";
import { Route, useHistory } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    color: "white",
    fontSize: "0.5em"
    
  },
  
  appBar: {
    height: "4px",
    paddingTop: "0px",
    background: "linear-gradient(45deg, #165ff2 30%, #193B59 90%)",
    transition: theme.transitions.create(["margin", "width", "background", "height"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    height: "65px",
    background: "linear-gradient(45deg, #165ff2 30%, #193B59 90%)",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width", "background", "height"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#222222",
    color: "white",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: "-90px",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    marginTop: "-30px",
  },
}));

const Home = () => {
  return (
    <div className="uiViewContainer">
      <p className="classData">Welcome, Landon!</p>
      <p className="classTitles">This is where all the awesomeness goes.</p>
    </div>
  );
};

const TeamLanding = () => {
  return <div className="loading"><p>Select A Team From The Menu</p></div>
}

const AdminUI = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const history = useHistory();

  const handleNavClick = (name) => {
    if (name === "Home") {
      history.push(`/admin`);
    } else if (name === "Registration Dashboard") {
      setOpen(false);
      history.push(`/admin/registrations`);
    } else if (name === "Live Dashboard") {
      setOpen(false);
      history.push(`/admin/live`);}
      else if (name === "Team Dashboard") {
        setOpen(false);
        history.push(`/admin/team/`);
    } else if (name === "Lookup Registration") {
      setOpen(false);
      history.push(`/admin/lookup/`);
    } else if (name === "Log Out") {
        sessionStorage.clear()
        window.location.href= '/access'
    } else {
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="subtitle1" noWrap>
            
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {["Home", "Registration Dashboard", "Live Dashboard", "Lookup Registration", "Team Dashboard"].map(
            (text, index) => (
              <ListItem button key={text}>
                <ListItemText
                  primary={text}
                  onClick={(name) => handleNavClick(`${text}`)}
                />
              </ListItem>
            )
          )}
        </List>
        <Divider />
        <List>
          {["Log Out"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemText
                primary={text}
                onClick={(name) => handleNavClick(`${text}`)}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Route exact path="/admin" component={Home} />
        <Route exact path="/admin/live/pelofondo" component={PeloFondoDashboard} />
        <Route exact path="/admin/live/stride" component={StrideDashboard}/>
        <Route exact path="/admin/lookup" component={LookupDashboard} />
        <Route exact path="/admin/team/:teamCode" component={RiderDashboard} />
        <Route exact path="/admin/team/" component={TeamLanding} />
        <Route
          exact
          path="/admin/registrations"
          component={RegistrationDashboard}
        />
      </main>
    </div>
  );
};

export default AdminUI;
