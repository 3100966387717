import React, { useEffect } from "react";
import Chart from "chart.js";
//import { withRouter } from "react-router-dom";
Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif";
Chart.defaults.global.legend.display = false;

const LineGraph = props => {
  const chartRef = React.createRef();
  const current = props.registrations()
  console.log(current)
  useEffect (() => {
    const myChartRef = chartRef.current.getContext("2d");

    const { height: graphHeight } = myChartRef.canvas;

    let gradientLine = myChartRef.createLinearGradient(0, 200, 0, graphHeight);
    gradientLine.addColorStop(1, "rgba(34,124,242,.4)");
    gradientLine.addColorStop(0.5, "rgba(34,124,242,.2)");
    gradientLine.addColorStop(0, "rgba(0,0,0,0)");

    new Chart(myChartRef, {
      type: "line",
      data: {
        //Bring in data
        labels: [
          "Jan 2019",
          "June 2019",
          "October 2019",
          "Jan 2020",
          "April 2020",
          "July 2020",
          "Oct 2020",
          "Jan 2021",
          "Apr 2021",
          "July 2021"
        ],
        datasets: [
          {
            label: "Riders",
            data: [47, 89, 223, 641, 1266, 2065, 3411, 10074, 9286, current],
            fill: true,
            backgroundColor: gradientLine,
            borderColor: "rgba(34,124,242,1)",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 30,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: { display: false },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: { display: true },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      },
    });
  }, [chartRef, current])
      return (
      <div className="contain">
        <p className="blockSubheader">Number Of Registrations Per</p>
        <p className="blockHeader">Event</p>
        <canvas id="myChart" ref={chartRef} />
      </div>
    );
  }

  export default LineGraph