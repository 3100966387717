import React from "react";
import { Container } from "react-grid-system";
import { BrowserRouter } from "react-router-dom";
import "./Site.css";
import background from "./assets/images/summiitwhite.png";



const Landing = () => (
  <BrowserRouter>
    <Container className="centerAll">
      <div className="blockContent">
        <img className="logo" src={background} alt="landing"></img>
        <p className="siteSubhead">coming soon...</p>
      </div>
    </Container>
  </BrowserRouter>
);

export default Landing;
