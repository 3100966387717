import React from "react";
import MainContainer from "./MainContainer";
import HeaderBar from "../../registrationDashboard/HeaderBar";


const Dashboard = () => {
 
  return (
    <div className="base">
      <div className="line"></div>
      <div className="header">
        <HeaderBar />
      </div>
      <MainContainer />
    </div>
  );
};

export default Dashboard;
