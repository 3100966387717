import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
//import RiderStats from "./RiderStats";
import GroupStats from "./GroupStats";
// import { RiderList } from "./TeamList";
import { db } from "../fire";
import AllRiderDetails from "./AllRiderDetails";
import TeamDetails from "./TeamDetails";
import ClassDetails from "./ClassDetails";
import RiderFeed from "./RiderFeed";

const MainView = () => {
  const [data, setData] = useState(null);
  const [classes, setClasses] = useState(null);
  const [feed, setFeed] = useState([]);

  function call() {
    db.collection("events").doc('SUMMIIT_STRIDE').collection("registrations")
    .where("metrics.currentMiles", ">", 0)
    
     
      .onSnapshot(function (querySnapshot) {
        var records = [];
        let feedData;

        //LISTEN TO ALL DOC CHANGES FOR MASTER CALCULATION
        querySnapshot.forEach(function (doc) {
          records.push(doc.data());
        });

        //LISTEN TO SPECIFIC CHANGES FOR FEED
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            feedData = change.doc.data().lbname + " laced up their shoes";
          }
          if (change.type === "modified") {
            if (change.doc.data().metrics.started === "started") {
              feedData = change.doc.data().lbname + " logged a run";
            } else {
              feedData =
                change.doc.data().lbname + " crossed the finish line 🏳";
            }
          }
        });

        setFeed((prev) => [feedData, ...prev.slice(0, 26)]);

        setData(records);
      });
  }

  console.log(feed);

  useEffect(() => {
    const fetchData = () => setTimeout(() => setData(call), 5000);
    fetchData();
  }, []);

  function queryClasses() {
    db.collection("schedule").onSnapshot(function (querySnapshot) {
      var records = [];

      querySnapshot.forEach(function (doc) {
        records.push(doc.data());
      });
      setClasses(records);
    });
  }

  useEffect(() => {
    const fetchData = () => setTimeout(() => setClasses(queryClasses), 1000);
    fetchData();
  }, []);

  if (!data | !classes) {
    return <p className="loading">Loading...</p>;
  } else if (data.length === 0) {
    return (
      <div>
        <p className="loading">
          No Data Exists. Check back after the first ride has been logged.
        </p>
      </div>
    );
  } else {
    // const NewData = () => {

    //   return (
    //   <></>
    //     )

    // }

    return (
      <div className="sidebarContainer">
        <div className="sidebarPrimary">
          <GroupStats pushData={data} />

          <Row className="liveDataRow">
            <Col sm={4}>
              <AllRiderDetails pushData={data} />
            </Col>
            <Col sm={8}>
              <TeamDetails pushData={data} />
            </Col>
          </Row>
          {/* <ClassDetails data={classes} /> */}
        </div>

        <div className="sidebar">
          <RiderFeed data={feed} />
        </div>
      </div>
    );
  }
};

export default MainView;
