import React from "react";
//import StaticData from "../data/StaticData";
import { Col, Row, Container } from "react-grid-system";
//import {countUp, update} from "react-countup"

const AllRiderDetails = (props) => {
  const data = props.pushData;

  const hasStarted = data.filter((filter) => {
    return filter.metrics.started === "started";
  });
  const hasFinished = data.filter((filter) => {
    return filter.metrics.started === "finished";
  });
  const overGoal = data.filter((filter) => {
    return filter.metrics.ascent >= filter.regData.distancePlanned;
  });

  const overCentury = data.filter((filter) => {
    return filter.metrics.ascent >= 10000;
  });

  const FinishedRiding = () => {
    let count = 0;
    hasFinished.forEach((doc) => {
      count++;
    });
    return count;
  };

  const StartedRiding = () => {
    let count = 0;
    hasStarted.forEach((doc) => {
      count++;
    });
    //const notFinished = count - riderFinished
    const localize = count.toLocaleString("en-US");
    FinishedRiding();
    return localize;
  };

  const OverGoal = () => {
    let count = 0;
    overGoal.forEach((doc) => {
      count++;
    });
    return count;
  };

  const OverCentury = () => {
    let count = 0;
    overCentury.forEach((doc) => {
      count++;
    });
    return count;
  };

  return (
    <Container className="middleContainer">
      <Row className="riderAnalyticsRow">
        <Col sm={6} className="squareStat">
          <h1 className="bigStatNum">{StartedRiding()}</h1>
          <p className="bigStatText">Climbing</p>
        </Col>
        <div className="vl"></div>
        <Col sm={6} className="squareStat">
          <h1 className="bigStatNum">{FinishedRiding()}</h1>
          <p className="bigStatText">Reached The Peak</p>
        </Col>
      </Row>
      <div>
        <p className="blockHeaderLive">ALL RIDER TOTALS</p>
      </div>
      <Row className="riderAnalyticsRow">
        <Col sm={6} className="squareStat">
          <h1 className="bigStatNum">{OverGoal()}</h1>
          <p className="bigStatText">Crushed Goals</p>
        </Col>
        <div className="vl"></div>
        <Col sm={6} className="squareStat">
          <h1 className="bigStatNum">{OverCentury()}</h1>
          <p className="bigStatText">Over 10,000 FT</p>
        </Col>
      </Row>
    </Container>
  );
};

export default AllRiderDetails;
