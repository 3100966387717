import React, { useEffect } from "react";
import { Row, Col, Container } from "react-grid-system";
import { useCountUp } from "react-countup";

const BasicStat = (props) => {
  //const [data, setData] = useState(props.data)
const {data, subtitle, decimal} = props
  const { countUp, update } = useCountUp({
    start: 0,
    end: 192332.23,
    delay: 0,
    duration: 2,
    preserveValue: true,
    decimals: decimal !== 2 ? decimal : 2,
    separator: ",",
    onUpdate: () => data,
  });

  useEffect(() => {
    update(data);
  }, [update, data]);

  return (
    <Container className="miniStat">
      <Row className="statRow">
        <Col sm={12} className="miniStatRight">
          <p className="groupBlockData">{countUp}</p>
        </Col>
      </Row>
      <div className="boldLine"></div>
      <div className="groupDetailText">{subtitle}</div>
    </Container>
  );
};


const HrView = (props) => {
  const Data = props.data;
  //const currentHours = hours
  const { countUp, update } = useCountUp({
    start: 0,
    end: Data,
    delay: 0,
    duration: 4,
    preserveValue: true,
    decimals: 0,
    separator: ",",
    onUpdate: () => Data,
  });

  useEffect(() => {
    update(Data);
  }, [update, Data]);
  return <span>{countUp} H : </span>;
};
const MinView = (props) => {
  const Data = props.data;
  //const currentMinutes = minutes
  const { countUp, update } = useCountUp({
    start: 0,
    end: Data,
    delay: 0,
    duration: 2,
    preserveValue: true,
    decimals: 0,
    onUpdate: () => Data,
  });

  useEffect(() => {
    update(Data);
  }, [update, Data]);
  return <span>{countUp} M</span>;
};





const GroupStats = (props) => {
  const data = props.pushData;

  //What is the total pledged distance? OUTPUT MINISTAT.JS
  const DistanceCompleted = (props) => {
    let count = [];
    data.forEach((doc) => {
      count.push(doc.metrics.currentMiles);
    });
    const total = count.reduce((total, num) => total + num);
    return total;
  };

  const AscentCompleted = (props) => {
    let count = [];
    data.forEach((doc) => {
      count.push(doc.metrics.ascent);
    });
    const total = count.reduce((total, num) => total + num);
    return total;
  };

  const TotalRideCount = () => {
    let count = [];
    data.forEach((doc) => {
      count.push(doc.metrics.rideCount);
    });
    const total = count.reduce((total, num) => total + num);
    return total;
  };

  const TotalTimeLogged = () => {
    let count = [];
    data.forEach((doc) => {
      count.push(doc.metrics.currentTime);
    });
    const total = count.reduce((total, num) => total + num);
    const hours = Math.floor(total / 60);
    //const humanHours = hours.toLocaleString("en-US")
    const minutes = Math.floor(total - hours * 60);
    return [hours, minutes];
  };

  const AscentSpeed = () => {
    let miles = [];
    data.forEach((doc) => {
      miles.push(doc.metrics.fpmActual);
    });
    const totalMiles = miles.reduce((total, num) => total + num);

    let time = [];
    data.forEach((doc) => {
      time.push(doc.metrics.currentTime);
    });
    const calcTime = time.reduce((total, num) => total + num);
    const totalTime = calcTime / 60;
    const avg = totalMiles / miles.length;
    const result = avg.toFixed(2);
    return result;
  };

  const Speed = () => {
    let miles = [];
    data.forEach((doc) => {
      miles.push(doc.metrics.currentMiles);
    });
    const totalMiles = miles.reduce((total, num) => total + num);

    let time = [];
    data.forEach((doc) => {
      time.push(doc.metrics.currentTime);
    });
    const calcTime = time.reduce((total, num) => total + num);
    const totalTime = calcTime / 60;
    const avg = totalMiles / totalTime;
    const result = avg.toFixed(2);
    return result;
  };

  const Output = () => {
    let output = [];
    data.forEach((doc) => {
      output.push(doc.metrics.outputWeighted / doc.metrics.currentTime);
    });
    const totalOutput = output.reduce((a, b) => a + b);
    const avg = totalOutput / output.length
    const result = avg;
    return result;
  };

  const Resistance = () => {
    let resistance = [];
    data.forEach((doc) => {
      resistance.push(doc.metrics.resistance / doc.metrics.rideCount);
    });
    const totalResistance = resistance.reduce((a, b) => a + b);
    const avg = totalResistance / resistance.length
    const result = avg.toFixed(2);
    return result;
  };

  

  const Cadence = () => {
    let resistance = [];
    data.forEach((doc) => {
      resistance.push(doc.metrics.cadence / doc.metrics.rideCount);
    });
    const totalResistance = resistance.reduce((a, b) => a + b);
    const avg = totalResistance / resistance.length
    const result = avg.toFixed(2);
    return result;
  };



  const Difficulty = () => {
    let diff = [];
    data.forEach((doc) => {
      diff.push(doc.metrics.newVam);
    });
    const totalDiff = diff.reduce((a, b) => a + b);

    function result(totalDiff) {
      switch (totalDiff) {
        case totalDiff <= 1499:
          return "Beginner";
        case totalDiff <= 1799:
          return "Intermediate";
        case totalDiff <= 1999:
          return "Advanced";
        case totalDiff >= 2000:
          return "Expert";
        default:
          return null;
      }
    }

    return result(totalDiff);
  };

  const avgSpeed = Speed();
  const totalTime = TotalTimeLogged();
  const classesTaken = TotalRideCount();
  const totalMiles = DistanceCompleted();
  const hours = totalTime[0];
  const minutes = totalTime[1];
  const avgOutput = Output();
  const avgResistance = Resistance();
  const avgDifficulty = Difficulty();
  const ascSpeed = AscentSpeed();
  const avgCadence = Cadence()
  const totalAscent = AscentCompleted()


  return (
    <>
      <Row className="analyticsRowFirst">
        <Col sm={3} className="miniStatContainer">
          <div className="groupStat">
            <BasicStat data={totalAscent} subtitle={"Ascent Completed"} decimal={2}/>
          </div>
        </Col>
        <Col sm={3} className="miniStatContainer">
          <div className="groupStat">
            <BasicStat data={avgOutput} subtitle={"Average Output (WATTS)"} decimal={0}/>
          </div>
        </Col>
        <Col ssm={3} className="miniStatContainer">
          <div className="groupStat">
            <BasicStat data={avgCadence} subtitle={"Average Cadence (RPM)"} decimal={0}/>
          </div>
        </Col>
        <Col sm={3} className="miniStatContainer">
          <div className="groupStat">
            <BasicStat data={avgResistance} subtitle={"Average Resistance"} decimal={0}/>
          </div>
        </Col>
      </Row>
      <Row className="analyticsRowFirst">
        <Col sm={3} className="miniStatContainer">
          <div className="groupStat">
            <BasicStat data={totalMiles} subtitle={"Total Miles"} decimal={2}/>
          </div>
        </Col>
        <Col sm={3} className="miniStatContainer">
          <div className="groupStat">
            <Container className="miniStat">
              <Row>
                <Col sm={12} className="miniStatRight">
                  <p className="groupBlockData">
                    <HrView data={hours} />
                    <MinView data={minutes} />{" "}
                  </p>
                </Col>
              </Row>
              <div className="boldLine"></div>
              <div className="groupDetailText">Total Climb Time</div>
            </Container>
          </div>
        </Col>
        <Col ssm={3} className="miniStatContainer">
          <div className="groupStat">
            <BasicStat data={ascSpeed} subtitle={"AVERAGE ft/min"} decimal={1}/>
          </div>
        </Col>
        <Col sm={3} className="miniStatContainer">
          <div className="groupStat">
            <BasicStat data={classesTaken} subtitle={"CLASSES TAKEN"} decimal={0}/>
          </div>
        </Col>
      </Row>
    
    </>
  );
};

export default GroupStats;
