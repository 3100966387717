import React, { useState, useEffect } from "react";
import {db} from "../fire";
//import StaticData from "../data/StaticData";
import { Col, Row, Container } from "react-grid-system";
import { InsertComment } from "@material-ui/icons";


const AllTeamDetails = (props) => {
  const [teams, setTeams] = useState(null)

  function call() {
    db.collection("teams")
        .onSnapshot(function (querySnapshot) {
          var records = [];
          querySnapshot.forEach(function (doc) {
            records.push(doc.data());
          });
          setTeams(records);
        });
  }
  
  
  useEffect(() => {
    const fetchData = () => setTimeout(() => setTeams(call), 1000)
    fetchData()
  }, []) 
  
  if (!teams) {
    return <p>...</p>
  }
  
  const race = teams.filter(item => item.category === 'Race Team').length; 
  const family = teams.filter(item => item.category === 'Family Team').length; 
  const challenge = teams.filter(item => item.category === 'Challenge Team').length; 
  const corporate = teams.filter(item => item.category === 'Corporate Team').length


  // const data = props.pushData

  return (
    <Container>
      <Row className="megaStat">
        <Col sm={6}>
          <h1 className="bigStatNum">{race}</h1>
          <p className="bigStatText">Race Teams</p>
        </Col>
        <Col sm={6}>
          <h1 className="bigStatNum">{challenge}</h1>
          <p className="bigStatText">Challenge Teams</p>
        </Col>
      </Row>
      <div className="boldLine"> </div>
      <Row className="megaStat2">
        <Col sm={6}>
          <h1 className="bigStatNum">{family}</h1>
          <p className="bigStatText">Family Teams</p>
        </Col>
        <Col sm={6}>
          <h1 className="bigStatNum">{corporate}</h1>
          <p className="bigStatText">Corporate Teams</p>
        </Col>
      </Row>
      <Row className="analyticsRow">
        <Col sm={12}>
          <div className="boldLine"> </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AllTeamDetails;
