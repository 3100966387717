import React, { useEffect} from "react";
import { Row, Col, Container } from "react-grid-system";
import {useCountUp} from "react-countup"



const MilesCompleted = props => { 
  //const [data, setData] = useState(props.data)
  const Data = props.data
  const { countUp, update } = useCountUp({
    start: 0,
    end: 192332.23,
    delay: 0,
    duration: 2,
    preserveValue: true,
    decimals: 2,
    separator: ",",
    onUpdate: () => Data,
  });

  useEffect(() => {
    update(Data)
  }, [update, Data]);

  return(
<Container className="miniStat">
<Row className="statRow">
<Col sm={12} className="miniStatRight">
  <p className="groupBlockData">{countUp}</p>
</Col>

</Row>
<div className="boldLine"></div>
<div className="groupDetailText">Total Miles Completed</div>
</Container>)}



  const HrView = (props) => {
    const Data = props.data
    //const currentHours = hours
    const { countUp, update } = useCountUp({
      start: 0,
      end: Data,
      delay: 0,
      duration: 4,
      preserveValue: true,
      decimals: 0,
      separator: ",",
      onUpdate: () => Data,
    });
  
    useEffect(() => {
      update(Data)
    }, [update, Data]);
    return(<span>{countUp} H : </span>)
  }
  const MinView = (props) => {
    const Data = props.data
    //const currentMinutes = minutes
    const { countUp, update } = useCountUp({
      start: 0,
      end: Data,
      delay: 0,
      duration: 2,
      preserveValue: true,
      decimals: 0,
      onUpdate: () => Data,
    });
  
    useEffect(() => {
      update(Data)
    }, [update, Data]);
    return(<span>{countUp} M</span>)
  }
  



const AvgSpeed = props => { 
  const Data = props.data
  const { countUp, update } = useCountUp({
    start: 0,
    end: Data,
    delay: 0,
    duration: 2,
    preserveValue: true,
    decimals: 2,
    onUpdate: () => Data,
  });

  useEffect(() => {
    update(Data)
  }, [update, Data]);
  return(
<Container className="miniStat">
<Row>
<Col sm={12} className="miniStatRight">
  <p className="groupBlockData">{countUp} MPH</p>
  <div className="boldLine"></div>
<div className="groupDetailText">Average Runner Speed</div>
</Col>
</Row>
</Container>)}

const ClassesTaken = props => { 
const Data = props.data
const { countUp, update } = useCountUp({
  start: 0,
  end: Data,
  delay: 0,
  duration: 3,
  preserveValue: true,
  decimals: 0,
  separator: ",",
  onUpdate: () => Data,
});

useEffect(() => {
  update(Data)
}, [update, Data]);
return(
<Container className="miniStat">
<Row>
<Col sm={12} className="miniStatRight">
  <p className="groupBlockData">{countUp}</p>
</Col>

</Row>
<div className="boldLine"></div>
<div className="groupDetailText">Total Classes Taken</div>
</Container>)};









const GroupStats = props => {
  const data = props.pushData

  
    //What is the total pledged distance? OUTPUT MINISTAT.JS
    const DistanceCompleted = props => {
      let count = [];
      data.forEach((doc) => {
        if(doc.unit ==='Kilometers'){
          const convert = doc.metrics.currentMiles * 0.62137
          count.push(convert)
        } else {
          count.push(doc.metrics.currentMiles);
        }
        
      });
      const total = count.reduce((total, num) => total + num);
      return total;
    };
  
    const TotalRideCount = () => {
      let count = [];
      data.forEach((doc) => {
        count.push(doc.metrics.rideCount);
      });
      const total = count.reduce((total, num) => total + num);
      return total;
    };
  
    const TotalTimeLogged = () => {
      let count = [];
      data.forEach((doc) => {
        count.push(doc.metrics.currentTime);
      });
      const total = count.reduce((total, num) => total + num);
      const hours = Math.floor(total / 60);
      //const humanHours = hours.toLocaleString("en-US")
      const minutes = Math.floor(total - hours * 60);
      return [hours, minutes];
    };
  
    const Speed = () => {
      let miles = [];
      data.forEach((doc) => {
        if(doc.unit ==='Kilometers'){
          const convert = doc.metrics.currentMiles * 0.62137
          miles.push(convert)
        } else {
          miles.push(doc.metrics.currentMiles);
        }
      });
      const totalMiles = miles.reduce((total, num) => total + num);
  
      let time = [];
      data.forEach((doc) => {
        time.push(doc.metrics.currentTime);
      });
      const calcTime = time.reduce((total, num) => total + num);
      const totalTime = calcTime / 60;
      const avg = totalMiles / totalTime;
      const result = avg.toFixed(2);
      return result;
    };
    
 
  const avgSpeed = Speed()
  const totalTime = TotalTimeLogged()
  const classesTaken = TotalRideCount()
  const totalMiles = DistanceCompleted()
  const hours = totalTime[0]
  const minutes = totalTime[1]
return(
    <Row className="analyticsRowFirst">
    <Col sm={3} className="miniStatContainer">
  <div className="groupStat"><MilesCompleted data={totalMiles}/></div>
  </Col>
    <Col sm={3} className="miniStatContainer">
      <div className="groupStat">
      <Container className="miniStat">
      <Row>
      <Col sm={12} className="miniStatRight">
        <p className="groupBlockData"><HrView data={hours}/><MinView data={minutes}/> </p>
      </Col>
      
      </Row>
      <div className="boldLine"></div>
      <div className="groupDetailText">Total Running Time</div>
      </Container></div>
    </Col>
    <Col ssm={3} className="miniStatContainer">
      <div className="groupStat"><AvgSpeed data={avgSpeed}/></div>
    </Col>
    <Col sm={3} className="miniStatContainer">
    <div className="groupStat"><ClassesTaken data={classesTaken}/></div>
  </Col>
  </Row>
)};

export default GroupStats;
