import React, { useState, useEffect } from "react";
//import StaticData from "../data/StaticData";
import { Col, Row, Container } from "react-grid-system";
//import CountUp from "react-countup"

//DATA FILTERS

const TeamDetails = (props) => {
  const [teamState, setTeamState] = useState(props.pushData);
  const [slide, setSlide] = useState(0);

  //SETS INITIAL DATA AND WATCHES FOR NEW DATA
  useEffect(() => {
    setTeamState(props.pushData);
  }, [props.pushData]);

  const teams = teamState.filter(
    (x) => x.regData.onTeam !== "null" && x.regData.onTeam !== undefined
  );
  const listTeams = [...new Set(teams.map((x) => x.regData.onTeam).sort())];

  let teamName = listTeams[slide];

  const setTeam = teamState.filter(
    (x) =>
      x.regData.onTeam === teamName && x.metrics.currentMiles !== undefined
  );
  const didStart = teamState.filter(
    (x) => x.metrics.started === "started" && x.regData.onTeam === teamName
  );
  const century = teamState.filter(
    (x) => x.metrics.currentMiles >= 100 && x.regData.onTeam === teamName
  );
  const didfinish = teamState.filter(
    (x) => x.metrics.started === "finished" && x.regData.onTeam === teamName
  );

  const allTeamData = () => {
    //const teamName = currentTeam;

    const calcDist = () => {
      let count = [];
      setTeam.forEach((doc) => {
        if (doc.metrics.currentMiles !== undefined) {
          if(doc.unit ==='Kilometers'){
            const convert = doc.metrics.currentMiles * 0.62137
            count.push(convert)
          } else {
            count.push(doc.metrics.currentMiles);
          }
        }
        if (doc.metrics.currentMiles === undefined) {
          count.push(0);
        }
      });
      const total = count.reduce((total, num) => total + num);
      return total.toFixed(2);
    };

    const calcClasses = () => {
      let count = [];
      setTeam.forEach((doc) => {
        if (doc.metrics.rideCount !== undefined) {
          count.push(doc.metrics.rideCount);
        }
        if (doc.metrics.rideCount === undefined) {
          count.push(0);
        }
      });
      const total = count.reduce((total, num) => total + num);
      return total;
    };

    const calcTime = () => {
      let count = [];
      setTeam.forEach((doc) => {
        if (doc.metrics.currentTime !== undefined) {
          count.push(doc.metrics.currentTime);
        }
        if (doc.metrics.currentTime === undefined) {
          count.push(0);
        }
      });
      const total = count.reduce((total, num) => total + num);
      return total;
    };

    const calcStarted = () => {
      let count = 0;
      didStart.forEach((doc) => {
        count++;
      });
      return count;
    };

    const calcFinished = () => {
      let count = 0;
      didfinish.forEach((doc) => {
        count++;
      });
      return count;
    };

    const calccentury = () => {
      let count = 0;
      century.forEach((doc) => {
        count++;
      });
      return count;
    };

    const state = {
      onTeam: teamName,
      distance: calcDist(),
      classes: calcClasses(),
      time: calcTime(),
      started: calcStarted(),
      finished: calcFinished(),
      century: calccentury(),
    };

    return state;
  };

  //PERFORMS CALCULATIONS AND
  useEffect(() => {
    //VISUALIZES THE DATA FOR THE COMPONENT OUTPUT
    function SetData() {
      var data = allTeamData();

      var team = data.onTeam;
      var distance = data.distance.toLocaleString("en-US");
      var classes = data.classes.toLocaleString("en-US");
      var time = data.time;
      var hours = Math.floor(time / 60);
      var humanHours = hours.toLocaleString("en-US");
      var minutes = Math.floor(time - hours * 60);
      var humanMinutes = minutes < 10 ? "0" + minutes : minutes;
      var humanTime = humanHours + " : " + humanMinutes;
      var started = data.started;
      var finished = data.finished;
      var century = data.century;

      document.getElementById("team").innerHTML = team;
      document.getElementById("distance").innerHTML = distance;
      document.getElementById("classes").innerHTML = classes;
      document.getElementById("time").innerHTML = humanTime;
      document.getElementById("started").innerHTML = started;
      document.getElementById("finished").innerHTML = finished;
      document.getElementById("century").innerHTML = century;
    }

    //SENDS THE DATA TO THE VIEW
    SetData();

    //CREATES THE LOOP FOR THE DATA CALCULATIONS
    setTimeout(() => {
      if (slide === listTeams.length - 1) {
        setSlide(0);
      }
      if (slide !== listTeams.length - 1) {
        setSlide(slide + 1);
      }
      SetData(listTeams[slide]);
      //console.log(slide)
    }, 3500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide]);

  return (
    <Container className="middleContainer">
      
      <Row className="teamAnalyticsRow">
        <Col sm={4} className="squareStat">
          <div className="bigStatNumTeam" id="distance"></div>
          <p className="bigStatText">Miles Logged</p>
        </Col>
        <div className="vl"></div>
        <Col sm={4} className="squareStat">
          <div className="bigStatNumTeam" id="classes"></div>
          <p className="bigStatText">Classes Taken</p>
        </Col>
        <div className="vl"></div>
        <Col sm={4} className="squareStat">
          <div className="bigStatNumTeam" id="time"></div>
          <p className="bigStatText">Total Riding Time</p>
        </Col>
      </Row>
      <div>
        <p id="team" className="blockHeaderLive"></p>
      </div>
      <Row className="teamAnalyticsRow">
        <Col sm={4} className="squareStat">
          <div className="bigStatNumTeam" id="started"></div>
          <p className="bigStatText">Riding Now</p>
        </Col>
        <div className="vl"></div>
        <Col sm={4} className="squareStat">
          <div className="bigStatNumTeam" id="finished"></div>
          <p className="bigStatText">Crossed The Finish</p>
        </Col>
        <div className="vl"></div>
        <Col sm={4} className="squareStat">
        <div className="bigStatNumTeam" id="century"></div>
        <p className="bigStatText">Completed 100+ Miles</p>
      </Col>
      </Row>
    </Container>
  );
};

export default TeamDetails;
