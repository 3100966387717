import React, { useState } from "react";
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  makeStyles,
  Button,
  ThemeProvider,
} from "@material-ui/core";
import Logo from "./assets/images/summiitwhite.png";
// import axios from "axios";
import { db, auth} from "../fire";

const theme = createTheme({
  palette: {
    primary: {
      light: "#222222",
      main: "#222222",
      dark: "#222222",
      contrastText: "#193B59",
    },
    secondary: {
      light: "#222222",
      main: "#222222",
      dark: "#222222",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles({
  button: {
    marginTop: 20,
    padding: 10,
  },
});

const LoginData = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState({});
  console.log(loading, errors);
  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading({ loading: true });


    
    auth.signInWithEmailAndPassword(email, password)
    .then((data) => {     
     console.log(data.user.getIdToken())
    return {uid: data.user.uid, token: data.user.getIdToken()}
    })
      .then(({uid, token}) => {
      console.log(uid, token)
      db.collection("users")
      .where("userID", "==", uid)
      .get()
      .then((querySnapshot) => {
        let handle
        querySnapshot.forEach((doc) => {
          // console.log(doc.data());
          localStorage.setItem("handle", doc.data().handle);
          if (doc.data().isAdmin) {
            sessionStorage.setItem("isAdmin", "true");
          }
          if (doc.data().teamName) {
            localStorage.setItem("teamName", doc.data().teamName);
          }
          // console.log(doc.data().handle)
          handle = doc.data().handle
          console.log(handle)
        });
        return handle
      })
      .then((handle) => {
        let event
        console.log(handle)
        db.collection("eventRegistrations")
        .doc(`${handle}`)
        .get()
        .then((user) => {
          console.log(user.data().event);
          sessionStorage.setItem("event", user.data().event);
          event = user.data().event
          return event
        })
        .then((event) => {
          setLoading({ loading: false });

          if (sessionStorage.isAdmin === "true") {
            window.location.href = "/admin";
          } else {
            window.location.href = "/user";
          }
        });
      })
    })

      .catch((err) => {
        setErrors({ err });
      });
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePass = (event) => {
    setPassword(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item sm />
        <Grid item sm>
          <img className="logo" src={Logo} alt="Logo"></img>
          <form noValidate onSubmit={handleSubmit} className="loginForm">
            <TextField
              id="email"
              name="email"
              type="email"
              label="Email"
              value={email}
              onChange={handleChangeEmail}
              fullWidth
              InputLabelProps={{ color: "primary" }}
              // className={classes.root}
            />
            <TextField
              id="password"
              name="password"
              type="password"
              label="Password"
              value={password}
              onChange={handleChangePass}
              fullWidth
              // className={classes.root}
            />
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              color="secondary"
              fullWidth
              className={classes.button}
            >
              Log In
            </Button>
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    </ThemeProvider>
  );
};

const Login = () => {
  return (
    <div className="mainView">
      <LoginData />
    </div>
  );
};

export default Login;
