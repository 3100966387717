import React from "react";
import MainContainer from "./MainContainerRider";
import HeaderBar from "./HeaderBarTeam";
import FooterBar from "./FooterBar"


const Dashboard = () => {
 
  return (
    <div className="base">
      <div className="line"></div>
      <div className="header">
        <HeaderBar />
      </div>
      <MainContainer />
      <div className="footer">
      <FooterBar />
      </div>
    </div>
  );
};

export default Dashboard;
