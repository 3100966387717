import React, { useState } from "react";
//import StaticData from "../data/StaticData";
import { Col, Row, Container } from "react-grid-system";



// const ListTeams = () => {
//   // constructor(props) {
//   //   super(props);
//   //   this.state = {team: "value"};
//   // }

//   return (
//     <div className="scroll">
//       <ul className="table">
//         {TeamList.map((team) => (
//           <button
//             className="teamButton"
//             value={team}
//             onClick={() => teamSelection(team)}
//             key={team}
//           >
//             {team}
//           </button>
//         ))}
//       </ul>
//     </div>
//   );
// };

const RiderList = props => {
  const [team, setTeam] = useState("SUMMIIT Strong");
  const data = props.pushData

  function notNull(value) {
    return value.regData.onTeam !== "null" && value.regData.onTeam !== undefined;
  }
  
  const teams = data.filter(notNull);
  
  const TeamList = [...new Set(teams.map((x) => x.regData.onTeam).sort())];
 

  const TeamFilter = data.filter(function (data) {
    //console.log(teamSelection())
    return data.regData.onTeam === team;
  });

  function teamSelection(selection) {
    setTeam(selection);
    // console.log(selection);
    return selection;
  }

  const NumRidersOnTeam = () => {
    //var calc = data
    let count = 0;
    TeamFilter.forEach(() => {
      count++;
    });
    const localize = count.toLocaleString("en-US")
    return localize
  };

  const TeamDistance = () =>  {
    let count = [];
    TeamFilter.forEach((doc) => {
      count.push(doc.regData.distancePlanned)
    })
    // console.log(count)
    const total = count.reduce((total, num) => total + num)
    const localize = total.toLocaleString("en-US")
    return localize;
  }

  const renderTableData = () => {
    return TeamFilter.map((rider, index) => {
      const { lbname, name, regData } = rider;
      return (
        <tr key={lbname}>
          <td className="riderName">{lbname}</td>
          <td>{name}</td>
          <td>{regData.distancePlanned} Miles</td>
          <td>{regData.rideDay}</td>
        </tr>
      );
    });
  };

  const renderTableHeader = () => {
    const header = ["Leaderboard Name", "Full Name", "Distance Planned", "Ride Day"];
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const handleClick = (value) => {
    teamSelection(value);
  };

  return (
    <Container className="col8">
      <Row>
        <Col sm={8}>
          <p className="blockSubheaderInner">Team Details</p>
          <p className="blockHeader">{team}</p>
          <p className="teamTotals"><NumRidersOnTeam /> Riders | <TeamDistance /> Miles Pledged</p>
        </Col>
        <Col sm={4} className="rightAlign">
          <select className="dropdown" onChange={(e) => handleClick(e.target.value)}>
            {TeamList.map((team) => (
              <option className="teamButton" value={team} key={team}>
                {team}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row className="analyticsRow">
        <Col sm={12}>
          <div className="scroll">
            <table id="riders">
              <tbody>
                <tr>{renderTableHeader()}</tr>
                {renderTableData()}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export { RiderList };
