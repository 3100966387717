import React from "react";
import MainContainer from "./MainContainer";
import HeaderBar from "./HeaderBar";


const LookupDashboard = () => {
 
  return (
    <div className="base">
      <div className="line"></div>
      
      <MainContainer />
    </div>
  );
};

export default LookupDashboard;
