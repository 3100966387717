import React from "react";


const RiderFeed = (props) => {
    const data = props.data
   

 
  return (
    <div>
        <div style={{fontSize: 24, color: '#dc493a'}}>RUNNER FEED: </div>
     <div style={{fontSize: 18, color: '#fff'}}>{data.map((item, index) => (
         <div key={index}>• {item}</div>
     ))}</div>
    </div>
  );
};

export default RiderFeed;


