import firebase from "firebase";

const firebaseApp = firebase.initializeApp ({
    apiKey: "AIzaSyDrx8qyYqk-1V1u1p9oiCzlrCUQSaOdQy0",
    authDomain: "pelofondo-analytics-dev.firebaseapp.com",
    databaseURL: "https://pelofondo-analytics-dev.firebaseio.com",
    projectId: "pelofondo-analytics-dev",
    storageBucket: "pelofondo-analytics-dev.appspot.com",
    messagingSenderId: "841157415172",
    appId: "1:841157415172:web:f234b9905d829d852d4051",
    measurementId: "G-X29DQWCNG3"
});

// const analytics = firebase.analytics()

const db = firebaseApp.firestore();
const auth = firebaseApp.auth()
const User = firebaseApp.User
export  {db, auth, User};