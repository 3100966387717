import React from "react";
import "./App.css";
import Landing from "./Site/Landing";
import RegistrationDashboard from "./registrationDashboard/Dashboard";
import StrideDashboard from "./strideLiveDashboard/AdminDashboard"
import PeloFondoDashboard from "./peloFondoLiveDashboard/AdminDashboard"
// import LiveRideDashboard from "./liveRideDashboard/AdminDashboard"
import { Route, BrowserRouter } from "react-router-dom";
import Login from "./Site/Login";
import AuthRoute from "./util/AuthRoute"
import AdminRoute from "./util/AdminRoute"
import AdminUI from "./AdminDashboard/AdminUI";
import TeamLeadUI from "./TeamLeadDashboard/TeamLeadUI"
import ClimbDashboard from "./climbLiveDashboard/AdminDashboard"

let authenticated;
let isAdmin

if(sessionStorage.isAdmin === "true") {
  isAdmin = true
} else {
  isAdmin = false
}

function App() {

  return (
    <BrowserRouter>
      <div className="base">
        <div className="line"></div>
        <AdminRoute path="/admin" component={AdminUI} isAdmin={isAdmin} />
        <AuthRoute
          path="/user"
          component={TeamLeadUI}
          authenticated={authenticated}
        />
        <Route exact path="/" component={Landing} />
        <Route path="/access" component={Login} />
        <AuthRoute
          exact
          path="/registrations"
          component={RegistrationDashboard}
          authenticated={authenticated}
        />
        {/* <AuthRoute exact path="/live/stride" component={StrideDashboard} authenticated={authenticated}/> */}
        <AuthRoute
          exact
          path="/admin/live/event/pelofondo"
          component={PeloFondoDashboard}
          authenticated={isAdmin}
        />
        <AuthRoute
          exact
          path="/admin/live/event/stride"
          component={StrideDashboard}
          authenticated={isAdmin}
        />
        <AuthRoute
          exact
          path="/admin/live/event/climb"
          component={ClimbDashboard}
          authenticated={isAdmin}
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
