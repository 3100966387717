import React, { useState } from "react";
import { Container } from "react-grid-system";
import { createTheme } from "@material-ui/core/styles";
import {

  TextField,
  makeStyles,
  Button,
  ThemeProvider,
} from "@material-ui/core";
import {db} from "../fire";


const theme = createTheme({
  typography: {
    fontSize: 16,
    fontWeight: 900,
    color: "white",
  },
  palette: {
    primary: {
      light: "#fff",
      main: "#165ff2",
      dark: "#eee",
      contrastText: "#fff",
    },
    secondary: {
      light: "#222222",
      main: "#222222",
      dark: "#222222",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        color: "#165ff2",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#165ff2",
        },
      },
    },
  },
});

const useStyles = makeStyles({
  button: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "#165ff2",
    fontSize: 12,
  },
  input: {
    color: "#eee",
  },
});

const MainView = () => {
  const [lbName, setLBName] = useState(null);
  const [name, setName] = useState(null);
  const [query, setQuery] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState("");
  const classes = useStyles();
  let queryType;

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading({ loading: true });

    const getDoc = () => {
      db.collection("eventRegistrations")
        .doc(`${query}`.toLowerCase())
        .get()
        .then((doc) => {
          console.log(doc.data());
          const string = JSON.stringify(doc.data());
          console.log(string);
          setData(doc.data());
        });
    };

    getDoc();
  };

  const RiderData = () => {
    if (data === null) {
      console.log("there is nothing");
      return (
        <div>
          <p>Enter A Leaderboard Name To Search Records</p>
        </div>
      );
    } else if (data === undefined) {
      return (
        <div>
          <p>This rider could not be found. Please check your spelling. </p>
        </div>
      );
    } else {
      let { lbname, name, pin, event, averageSpeed, teamAccess } = data;
      let { distancePlanned, onTeam, rideDay, teamCode } = data.regData;

      if (onTeam === undefined) {
        onTeam = "N/A";
        teamCode = "N/A";
      }
      if (teamAccess ? (teamAccess = "True") : (teamAccess = "False"))
        if (pin ? pin : (pin = "N/A"))
          return (
            <p className="lookupResults">
              Name: {name} <br />
              Leaderboard: {lbname} <br />
              Event Tag: {event} <br />
              Web Pin Login: {pin} <br />
              Team Lead Access: {teamAccess}
              <br />
              <br />
              Average Speed: {averageSpeed} <br />
              Distance Planned: {distancePlanned} <br />
              Ride Day: {rideDay} <br />
              Is On Team: {onTeam} <br />
              Team Code: {teamCode} <br />
            </p>
          );
    }
  };

  const handleChangeLBName = (event) => {
    setLBName(event.target.value);
    setQuery(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container className="mainView">
        <form noValidate onSubmit={handleSubmit} className="searchForm">
          <TextField
            id="Leaderboard Name"
            name="LB Name"
            type="text"
            label="Input Leaderboard Name"
            value={lbName}
            onChange={handleChangeLBName}
            fullWidth
            InputProps={{ className: classes.input }}
          />
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            color="secondary"
            fullWidth
            className={classes.button}
          >
            Find Rider
          </Button>
        </form>

        <RiderData />
      </Container>
    </ThemeProvider>
  );
};

export default MainView;
