import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-grid-system";
import MiniStat from "./MiniStat";
import { RiderList } from "./TeamList";
import MilesByVolume from "./MilesByVolume"
import {db} from "../../fire";

const MainView = () => {
  const [data, setData] = useState(null);
  const teamName = localStorage.teamName
  const eventDate = sessionStorage.event

function call() {
  db.collection("eventRegistrations")
  .where("event", "==", eventDate)
  .where("regData.onTeam", "==", teamName)
      .onSnapshot(function (querySnapshot) {
        var records = [];
        querySnapshot.forEach(function (doc) {
          records.push(doc.data());
        });
        setData(records);
      });
}


useEffect(() => {
  const fetchData = () => setTimeout(() => setData(call), 5000)
  fetchData()
}, []) 

if (!data) {
  return<p className="loading">Loading...</p>
}



// REQUIRE TEAM VALUE
function notNull(value) {
  return value.regData.onTeam !== "null" && value.regData.onTeam !== undefined;
}

// function distanceFilter(value) {
//   return value.regData.distancePlanned > value;
// }

//DATA SETS
const teams = data.filter(notNull)

const greaterDistance = data.filter(filter => {return filter.regData.distancePlanned > 99})
const distance35 = data.filter(filter => {return filter.regData.distancePlanned < 34})
const distance50 = data.filter(filter => {return filter.regData.distancePlanned > 34 && filter.regData.distancePlanned <=49})
const distance62 = data.filter(filter => {return filter.regData.distancePlanned > 49 && filter.regData.distancePlanned <=61})
const distance80 = data.filter(filter => {return filter.regData.distancePlanned > 61 && filter.regData.distancePlanned <=79})
const distance99 = data.filter(filter => {return filter.regData.distancePlanned > 79 && filter.regData.distancePlanned <=99})

const TotalRegistrations = () => {
  let count = 0;
  data.forEach((doc) => {
    count++;
  });
  return count
};

//What is the total pledged distance? OUTPUT MINISTAT.JS
const TotalDistance = (StaticData) =>  {
  let count = [];
  data.forEach((doc) => {
    count.push(doc.regData.distancePlanned)
  })
  const total = count.reduce((total, num) => total + num)
  const localize = total
  return localize;
}

//How Many Teams Are Registered?  OUTPUT MINISTAT.JS
const CountTeams = () => {
  let count = 0;
  teams.forEach((doc) => {
    count++;
  })
 const localize = count.toLocaleString("en-US")
  return localize;
};

const Century = (data) =>  {
  let count = 0;
  greaterDistance.forEach((doc) => {
    count++
  })
  return count;
}

const Miles35 = (data) =>  {
  let count = 0;
  distance35.forEach((doc) => {
    count++
  })
  return count;
  
}

const Miles50 = (data) =>  {
  let count = 0;
  distance50.forEach((doc) => {
    count++
  })
  return count;
  
}


const Miles62 = (data) =>  {
  let count = 0;
  distance62.forEach((doc) => {
    count++
  })
  return count;
  
}


const Miles80 = (data) =>  {
  let count = 0;
  distance80.forEach((doc) => {
    count++
  })
  return count;
  
}

const Miles99 = (data) =>  {
  let count = 0;
  distance99.forEach((doc) => {
    count++
  })
  return count;
  
}
  
  
  return(
  <Container className="mainView">
    <MiniStat pushData={data} centuryData={Century} riderData={TotalRegistrations} teamData={CountTeams} distanceData={TotalDistance}/>
    
    <Row className="analyticsRow">
    <Col sm={4}>
        <div className="col12">
        <MilesByVolume data35={Miles35} data50={Miles50} data62={Miles62} data80={Miles80} data99={Miles99} century={Century}/>
</div>
      </Col>
      <Col sm={8}>
        <div>
        <RiderList pushData={data}/>
        </div>
      </Col>
    </Row>
  </Container>
);
}

export default MainView;
